import ValuationModelWrapper from "~/components/valuationModels/Pe/ValuationModelWrapper.vue";
import WarningMsg from "~/components/WarningMsg.vue";
import { VALUATION_MODEL } from "~/feature-toggle";
export default {
  __name: 'index',
  setup: function setup(__props) {
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var $route = useRoute();
    var modelId = $route.query.modelId;
    useSeoMeta({
      title: "Valuation Model Preview - TIKR.com",
      ogImage: "https://tikr-email-assets.s3.amazonaws.com/pe-model-share-example.jpg",
      ogImageSecureUrl: "https://tikr-email-assets.s3.amazonaws.com/pe-model-share-example.jpg",
      ogImageAlt: "https://tikr-email-assets.s3.amazonaws.com/pe-model-share-example.jpg",
      twitterImage: "https://tikr-email-assets.s3.amazonaws.com/pe-model-share-example.jpg"
    });
    var errorMessage = computed(function () {
      var _$store$state$valuati;
      return (_$store$state$valuati = $store.state.valuationModel) === null || _$store$state$valuati === void 0 || (_$store$state$valuati = _$store$state$valuati.error) === null || _$store$state$valuati === void 0 ? void 0 : _$store$state$valuati.message;
    });
    return {
      __sfc: true,
      $store: $store,
      $route: $route,
      modelId: modelId,
      errorMessage: errorMessage,
      ValuationModelWrapper: ValuationModelWrapper,
      WarningMsg: WarningMsg,
      VALUATION_MODEL: VALUATION_MODEL
    };
  }
};